@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
:root {
  --shadow : 0 10px 20px rgba(0, 30, 149, 0.1);
}
body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.main-wrap {
  background-color: white;
  height: 100vh;
  width: 100vw;
}
.main-header {
  width: 100vw;
  position: fixed;
  top: 0;
  box-shadow: var(--shadow);
}

.header-name {
  padding: 0.5em 0;
}

.wrap {
  position: absolute;
  display: flex;
  /* background-color: red; */
  width: 100%;
  height: 100%;
}

.inbox {
  width: 768px;
  height: 512px;
  /* background-color: rebeccapurple; */
  border-radius: 10px;
  margin: auto;
  box-shadow: var(--shadow);
  padding: 2em;
}

hr {
  opacity: 0.1;
}

.num-item {
  /* padding-right: 1em; */
}

.x-item {
  opacity: 0.5;
}

.parenter {
  display: flex;
  flex-direction: column;
}

.encloser {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  text-align: center;
  background-color: rgb(240, 240, 250);
  padding: 1em;
  border-radius: 10px;
}

.encloser hr {
  margin: 0.5em;
  opacity: 0.2;
  border-width: 1.5px;
}

.toolbox {
  margin-top: 2em;
}

input[type=text] {
  /* background-color: red; */
  border: none;
  border-bottom: 1px solid rgba(0, 30, 149, 0.1);
  width: 80px;
  /* min-width: auto; */
  /* width: 100%; */
  /* width: auto;
  text-align: right; */
}

.toolbox .x-item {
  padding-left: 1em;
  padding-right: 1em;
}

.button {
  background-color: rgba(0, 36, 179, 0.703);
  width: 160px;
  color: white;
  text-align: center;
  padding: 0.5em 0.5em;
  border-radius: 10px;
  box-shadow: var(--shadow);
  transition: 60ms linear;
  cursor: pointer;
  display: inline-block;
  margin-right: 1em;
}

.button:hover {
  transform: scale(1.02);
}

.button:active {
  transform: scale(0.99);
}